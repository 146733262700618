html,
body,
#root {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

#root {
    display: flex;
    flex-direction: column;
}

.leaflet-popup-content p {
    margin: unset !important;
}

.dimmed-icon {
    opacity: 0.25;
}

.youtube-container {
    position: relative;
    margin-top: 1em;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}
.youtube-container iframe,
.youtube-container object,
.youtube-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
